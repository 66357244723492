<template>
  <div class="footer-wrapper body-m">
    <div class="footer-container">
      <div>
        <div class="body-m-bold text-light text-start">About</div>
        <div class="footer-list-container">
          <p>
            <router-link class="text-light text-decoration-none" to="/our-mission">Our Mission</router-link>
          </p>
          <p>
            <router-link class="text-light text-decoration-none" to="/quality-standards">Quality Standards</router-link>
          </p>
          <p>
            <router-link class="text-light text-decoration-none" to="/referral-program">Marketing</router-link>
          </p>
          <p>
            <router-link class="text-light text-decoration-none" to="/team">Team</router-link>
          </p>
          <p>
            <router-link class="text-light text-decoration-none" to="/imprint">Imprint</router-link>
          </p>
          <p>
            <router-link class="text-light text-decoration-none" to="/privacy">Privacy</router-link>
          </p>
        </div>
      </div>
      <div>
        <div class="body-m-bold text-light text-start">Earn Money</div>
        <div class="footer-list-container">
          <p>
            <router-link class="text-light text-decoration-none" to="/become-seller">Become Seller</router-link>
          </p>
          <p>
            <router-link class="text-light text-decoration-none" to="/referral-program">Referral Program</router-link>
          </p>
        </div>
      </div>
      <div>
        <div class="body-m-bold text-light text-start">Contact</div>
        <div class="footer-list-container">
          <p>info@small-shops.com</p>
          <p>+49 176 61909402</p>
            <a class="social-link" href="https://www.instagram.com/smallshopscom/" target="_blank">
              <div class="social-row">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24"  viewBox="0 0 27.098 27.092" style="margin-right: 7px;">
                      <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M13.547,8.838a6.946,6.946,0,1,0,6.946,6.946A6.935,6.935,0,0,0,13.547,8.838Zm0,11.462a4.516,4.516,0,1,1,4.516-4.516A4.524,4.524,0,0,1,13.547,20.3ZM22.4,8.553a1.62,1.62,0,1,1-1.62-1.62A1.616,1.616,0,0,1,22.4,8.553ZM27,10.2a8.018,8.018,0,0,0-2.188-5.676,8.07,8.07,0,0,0-5.676-2.188c-2.237-.127-8.941-.127-11.178,0A8.059,8.059,0,0,0,2.278,4.515,8.044,8.044,0,0,0,.09,10.192c-.127,2.237-.127,8.941,0,11.178a8.018,8.018,0,0,0,2.188,5.676,8.08,8.08,0,0,0,5.676,2.188c2.237.127,8.941.127,11.178,0a8.017,8.017,0,0,0,5.676-2.188A8.07,8.07,0,0,0,27,21.369c.127-2.237.127-8.935,0-11.172Zm-2.89,13.571a4.572,4.572,0,0,1-2.575,2.575c-1.783.707-6.015.544-7.986.544s-6.208.157-7.986-.544a4.572,4.572,0,0,1-2.575-2.575c-.707-1.783-.544-6.015-.544-7.986S2.284,9.575,2.986,7.8A4.572,4.572,0,0,1,5.561,5.223c1.783-.707,6.015-.544,7.986-.544s6.208-.157,7.986.544A4.572,4.572,0,0,1,24.107,7.8c.707,1.783.544,6.015.544,7.986S24.815,21.992,24.107,23.769Z" transform="translate(0.005 -2.238)" fill="#fff"/>
                  </svg>
                  Instagram
              </div>
            </a>
            <a class="social-link" href="https://twitter.com/small_shops" target="_blank">
              <div class="social-row">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 28.372 23.141" style="margin-right: 5px;">
                      <path id="Icon_feather-twitter" data-name="Icon feather-twitter" d="M29.872,4.5a14.057,14.057,0,0,1-4.049,1.973A5.778,5.778,0,0,0,15.686,10.34v1.29A13.748,13.748,0,0,1,4.079,5.787s-5.159,11.607,6.448,16.765A15.011,15.011,0,0,1,1.5,25.132c11.607,6.448,25.793,0,25.793-14.831a5.8,5.8,0,0,0-.1-1.07A9.956,9.956,0,0,0,29.872,4.5Z" transform="translate(-1.5 -4.485)" fill="#fff"/>
                  </svg>
                  Twitter
              </div>
            </a>
        </div>
      </div>
      <div>
        <div class="body-m-bold text-light text-start">Payment Systems</div>
        <div class="footer-list-container">
          <p>Corporate Payment Providers:</p>
          <p id="payment-providers">
            <img src="../assets/icons/Corporate_Payment_Providors.svg" style="height: 24px; color: white;">
          </p>
          <p></p>
          <p>Peer-to-Peer Electronic Cash:</p>
            <router-link to="/bitcoin">
          <p><svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 0 150.066 31.335">
            <g id="Bitcoin_logo" transform="translate(-289.604 -317.508)">
              <path id="Path_731" data-name="Path 731" d="M320.467,336.966a15.662,15.662,0,1,1-11.409-18.989,15.663,15.663,0,0,1,11.409,18.989Z" fill="#f7931a"/>
              <path id="Path_732" data-name="Path 732" d="M320.125,336.635c.312-2.085-1.275-3.205-3.446-3.953l.7-2.824-1.719-.428-.685,2.75c-.452-.113-.916-.219-1.377-.324l.69-2.768-1.718-.428-.7,2.823c-.374-.085-.741-.169-1.1-.258l0-.009-2.371-.592-.457,1.836s1.275.292,1.248.31a.915.915,0,0,1,.8,1l-.8,3.217a1.426,1.426,0,0,1,.179.057l-.182-.045-1.124,4.507a.626.626,0,0,1-.788.408c.017.025-1.249-.312-1.249-.312l-.853,1.968,2.237.558c.416.1.824.213,1.225.316l-.711,2.856,1.717.428.7-2.826c.469.127.924.245,1.37.355l-.7,2.813,1.719.428.711-2.851c2.931.555,5.135.331,6.063-2.32.748-2.135-.037-3.366-1.579-4.169a2.741,2.741,0,0,0,2.195-2.524Zm-3.928,5.508c-.531,2.135-4.125.981-5.291.691l.944-3.784c1.165.291,4.9.867,4.347,3.093Zm.532-5.538c-.485,1.942-3.476.955-4.447.713l.856-3.432C314.109,334.127,317.234,334.579,316.73,336.6Z" transform="translate(-7.948 -5.691)" fill="#fff"/>
              <path id="Path_733" data-name="Path 733" d="M368.955,326.993a7.479,7.479,0,0,1,3.291.675,6.626,6.626,0,0,1,2.305,1.824,7.684,7.684,0,0,1,1.346,2.7,12.318,12.318,0,0,1,.434,3.331,13.562,13.562,0,0,1-3.766,9.419,12.963,12.963,0,0,1-4.124,2.852,12.59,12.59,0,0,1-5.152,1.052c-.239,0-.659-.007-1.252-.019a18.3,18.3,0,0,1-2.039-.177,23.942,23.942,0,0,1-2.44-.478,12.222,12.222,0,0,1-2.44-.872l6.86-28.826,6.145-.952-2.456,10.229a9.555,9.555,0,0,1,1.584-.554,7.038,7.038,0,0,1,1.7-.2ZM363.8,343.968a5.384,5.384,0,0,0,2.616-.675,6.888,6.888,0,0,0,2.124-1.8,9.1,9.1,0,0,0,1.407-2.559,8.717,8.717,0,0,0,.516-2.976,5.857,5.857,0,0,0-.634-2.973,2.493,2.493,0,0,0-2.34-1.071,7.34,7.34,0,0,0-1.446.2,3.61,3.61,0,0,0-1.608.835l-2.615,10.863c.158.028.3.054.416.079a3.1,3.1,0,0,0,.374.059,4.308,4.308,0,0,0,.478.023h.712Z" transform="translate(-33.438 -0.005)" fill="#fff"/>
              <path id="Path_734" data-name="Path 734" d="M405.342,348.706h-5.869l4.956-20.856h5.909Zm2.856-23.4a3.642,3.642,0,0,1-2.221-.732,2.591,2.591,0,0,1-.993-2.241,3.73,3.73,0,0,1,.336-1.565,4.185,4.185,0,0,1,.894-1.27,4.39,4.39,0,0,1,1.288-.853,3.932,3.932,0,0,1,1.569-.316,3.642,3.642,0,0,1,2.219.732,2.616,2.616,0,0,1,.99,2.241,3.7,3.7,0,0,1-.337,1.568,4.128,4.128,0,0,1-.891,1.27,4.311,4.311,0,0,1-1.288.851A3.866,3.866,0,0,1,408.2,325.31Z" transform="translate(-56.077 -0.421)" fill="#fff"/>
              <path id="Path_735" data-name="Path 735" d="M427.53,326.167l6.147-.952-1.509,6.147h6.583l-1.189,4.836h-6.544l-1.743,7.3a8.247,8.247,0,0,0-.277,1.7,3.114,3.114,0,0,0,.2,1.369,1.72,1.72,0,0,0,.852.89,3.9,3.9,0,0,0,1.764.32,9.39,9.39,0,0,0,1.846-.18,13.092,13.092,0,0,0,1.8-.495l.438,4.522a21.06,21.06,0,0,1-2.577.752,14.842,14.842,0,0,1-3.292.316,9.114,9.114,0,0,1-4.244-.81,4.714,4.714,0,0,1-2.142-2.224,7.067,7.067,0,0,1-.555-3.229,20.907,20.907,0,0,1,.555-3.847l3.888-16.415Z" transform="translate(-68.125 -3.933)" fill="#fff"/>
              <path id="Path_736" data-name="Path 736" d="M454.56,349.716a14.645,14.645,0,0,1,.872-5.076,12.487,12.487,0,0,1,2.5-4.166,11.707,11.707,0,0,1,3.947-2.814,12.61,12.61,0,0,1,5.172-1.031,13.666,13.666,0,0,1,3.191.338,12.921,12.921,0,0,1,2.56.891l-2.024,4.6c-.529-.214-1.078-.4-1.645-.575a7.158,7.158,0,0,0-2.081-.259,5.815,5.815,0,0,0-4.66,2.021,8.1,8.1,0,0,0-1.726,5.434,5.587,5.587,0,0,0,.874,3.272,3.655,3.655,0,0,0,3.211,1.248,10.294,10.294,0,0,0,2.221-.237,10.943,10.943,0,0,0,1.9-.594l.438,4.718a21.233,21.233,0,0,1-2.459.775,13.667,13.667,0,0,1-3.213.334,10.842,10.842,0,0,1-4.163-.712,7.927,7.927,0,0,1-2.814-1.924,7.249,7.249,0,0,1-1.607-2.837A11.638,11.638,0,0,1,454.56,349.716Z" transform="translate(-84.193 -9.759)" fill="#fff"/>
              <path id="Path_737" data-name="Path 737" d="M499.215,358.6a9.6,9.6,0,0,1-3.649-.636,6.737,6.737,0,0,1-2.556-1.784,7.709,7.709,0,0,1-1.527-2.715,10.97,10.97,0,0,1-.513-3.466,15.3,15.3,0,0,1,.771-4.761,13.478,13.478,0,0,1,2.282-4.281,12.187,12.187,0,0,1,3.685-3.117,10.142,10.142,0,0,1,4.993-1.208,9.646,9.646,0,0,1,3.632.636,6.786,6.786,0,0,1,2.577,1.784,7.745,7.745,0,0,1,1.523,2.717,11,11,0,0,1,.516,3.468A15.811,15.811,0,0,1,510.2,350a13.746,13.746,0,0,1-2.221,4.281,11.639,11.639,0,0,1-3.667,3.111A10.411,10.411,0,0,1,499.215,358.6Zm2.933-16.971a3.708,3.708,0,0,0-2.3.752,6.478,6.478,0,0,0-1.664,1.9,9.4,9.4,0,0,0-1.014,2.518,10.853,10.853,0,0,0-.335,2.6,6.326,6.326,0,0,0,.633,3.1,2.425,2.425,0,0,0,2.3,1.109,3.689,3.689,0,0,0,2.3-.755,6.51,6.51,0,0,0,1.664-1.9,9.427,9.427,0,0,0,1.014-2.518,11,11,0,0,0,.335-2.6,6.277,6.277,0,0,0-.636-3.094,2.424,2.424,0,0,0-2.3-1.109Z" transform="translate(-102.777 -9.759)" fill="#fff"/>
              <path id="Path_738" data-name="Path 738" d="M538.717,348.706h-5.874l4.956-20.856h5.912Zm2.851-23.4a3.635,3.635,0,0,1-2.219-.732,2.6,2.6,0,0,1-.993-2.241,3.723,3.723,0,0,1,.338-1.565,4.133,4.133,0,0,1,.893-1.27,4.381,4.381,0,0,1,1.289-.853,3.9,3.9,0,0,1,1.564-.316,3.643,3.643,0,0,1,2.222.732,2.614,2.614,0,0,1,.994,2.241,3.759,3.759,0,0,1-.34,1.568,4.115,4.115,0,0,1-.89,1.27,4.355,4.355,0,0,1-1.288.851A3.878,3.878,0,0,1,541.568,325.31Z" transform="translate(-124.149 -0.421)" fill="#fff"/>
              <path id="Path_739" data-name="Path 739" d="M558.676,338.22c.445-.13.943-.287,1.484-.457s1.15-.329,1.827-.472a21.87,21.87,0,0,1,2.259-.359,25.394,25.394,0,0,1,2.833-.141q4.643,0,6.407,2.7t.617,7.375l-2.7,11.261h-5.907l2.616-11.024c.16-.688.285-1.354.377-2a4.749,4.749,0,0,0-.021-1.7,2.04,2.04,0,0,0-.731-1.191,2.836,2.836,0,0,0-1.766-.455,11.924,11.924,0,0,0-2.338.241l-3.847,16.137h-5.91Z" transform="translate(-134.883 -9.842)" fill="#fff"/>
            </g>
          </svg></p></router-link>
        </div>
      </div>
    </div>
    <div class="text-light mt-5 label-m">&#169; 2023 small-shops. All rights reserved</div>
  </div>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style scoped>

.social-link{
    color: white;
    text-decoration: none;
    text-align: start;
}

.social-link:hover{
    text-decoration: none;
    color: white;
}

.social-row{
    display: flex;
    justify-content: start;
}

.footer-wrapper{
  width: 100%;
  margin: 0;
  background: #011e0a;
  padding-top: 75px;
  padding-bottom: 25px;
  margin-top: calc(8*var(--column));
  position: relative;
  z-index: 1;
}
h4{
  color: white;
  text-align: left;
}

p{
  color: white;
  text-align: left;
}



@media screen and (min-width:1350px){
  .footer-container{
    width: 65vw;
    margin: auto;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .footer-list-container{
    margin-top: var(--space);
    display: grid;
    align-items: baseline;
    row-gap: 0;
    grid-template-rows: repeat(6, 35px);
  }

  #payment-providers{
    margin-top: 3px;
    margin-bottom: -3px;
  }
}

@media screen and (max-width: 1349px) and (min-width: 744px){
    .footer-container{
        width: 75vw;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, 33%);
        column-gap: var(--tablet-space);
        row-gap: calc(2*var(--column));
    }

    .footer-list-container{
        margin-top: calc(1.5*var(--tablet-space));
        display: grid;
        align-items: baseline;
        row-gap: 0;
        grid-template-rows: repeat(6, 35px);
    }

    #payment-providers{
        margin-top: 3px;
        margin-bottom: -3px;
    }
}

@media screen and (max-width: 743px) {
    .footer-wrapper{
        width: 100%;
        margin: 0;
        background: #011e0a;
        padding-top: 41px;
        padding-bottom: 25px;
        margin-top: calc(8*var(--column));
        position: relative;
        z-index: 1;
    }

    .footer-container {
        width: calc(100vw - 5 * var(--space));
        margin: auto;
        display: grid;
        grid-template-columns: 100%;
        row-gap: 25px;
    }

    .footer-list-container{
        margin-top: calc(2*var(--mobile-space));

    }

    .social-row {
        display: flex;
        justify-content: start;
        margin-bottom: 16px;
    }
}

</style>