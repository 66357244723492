<template>
    <div class="product-card" :id="'product-card'+this.$props.productId" @mouseover="this.showArrows = true;" @mouseleave="this.showArrows=false;">
            <div class="product-card-img">
              <div v-if="productImages.length>0">
                  <div v-if="imageLimit && mobileView">
                      <img  :src="productImages[0].productImageDtoSquareUrl" :alt="product.productDtoTitle" class="product-card-img">
                  </div>
                  <div v-else>
                      <carousel
                              :items-to-show="1.0"
                              :wrapAround="true"
                              @change="sendUserAction">
                          <slide v-for="(productImage, index) of productImages" :key="index">
                              <router-link class=" text-dark text-decoration-none" :to="{name: 'ProductDetails', params: {id: product.productDtoId}}">
                                  <img  :src="productImage.productImageDtoSquareUrl" :alt="product.productDtoTitle" class="product-card-img">
                              </router-link>
                          </slide>
                          <template #addons>
                              <navigation v-if="showArrows || mobileView"/>
                          </template>
                      </carousel>
                  </div>
              </div>
              <router-link v-else class=" text-dark text-decoration-none" :to="{name: 'ProductDetails', params: {id: product.productDtoId}}">
                  <div style="background-color: var(--lightGray);" class="product-card-img"></div>
              </router-link>
            </div>
          <div class="product-card-info">
              <router-link class="text-dark text-decoration-none" :to="{name: 'ShopDetails', params: { id: product.shopSlug }}">
                  <div v-if="product.shopName != null" class="label-m-light" id="shop-name">{{ product.shopName }}</div>
                  <div v-else style="background-color: var(--lightGray); height: 20px; width: 100%; margin: 2px;"></div>
              </router-link>
              <router-link class=" text-dark text-decoration-none" :to="{name: 'ProductDetails', params: {id: product.productDtoId}}">
                  <div v-if="product.shopName != null" class="body-m" id="product-title">{{ product.productDtoTitle }} </div>
                  <div v-else style="background-color: var(--lightGray); height: 20px; width: 100%; margin: 2px;"></div>
                  <div v-if="product.shopName != null" class="body-l-bold">{{ product.productDtoPrice.toFixed(2) }} €</div>
                  <div v-else style="background-color: var(--lightGray); height: 20px; width: 100%; margin: 2px;"></div>
              </router-link>
          </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import axios from 'axios';
export default {
name: "ProductCard",
components: {Carousel,  Slide, Navigation},
props: ["productId", "imageLimit"],
emits:["minmaxPrice"],
data(){
  return{
    showArrows: false,
    mobileView: false,
    quantity: 1,
    productImages: [],
    product: {
        shopName: null,
        shopSlug: "/slug",
        productDtoId: -1,
        productDtoTile: "product title",
        productDtoPrice: 0.00
    },
    loaded: false,
    imageUrl: ""
  }
},
methods:{
    async getProduct() {
        await axios({
            method: "get",
            url: this.$backendURL+"/product/get/"+this.$props.productId
        })
            .then(res => {
                const price = parseFloat( res.data.productDtoPrice)
                this.$emit("minmaxPrice", price)
                this.product = res.data;
            })
            .catch(err => console.error(err));
    },
    async sendUserAction(){
        let url = this.$backendURL + "/action/send?action=ProductSlider&note=ProductId: " + this.$props.productId
        if(localStorage.getItem('token') != null){
            url += "&authenticationToken=" + localStorage.getItem('token')
        }
        await axios.post(url)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.error(err);
            })
    },
  async getProductImages(){
    await axios({
      method: "get",
      url: this.$backendURL + "/product-image/getByProductId/" + this.$props.productId
    })
        .then(res => {
          this.productImages = res.data.slice(0, 2);
          if(this.productImages[0].productImageDtoSquareUrl != null){
              this.imageUrl = this.productImages[0].productImageDtoSquareUrl;
          }else{
              this.imageUrl = this.productImages[0].productImageDtoImageUrl;
          }
          this.loaded = true;
        })
        .catch(err => {
          console.error(err);
        })
  },
  setUp(){
      const options = {
          root: null, // Use the viewport as the root
          rootMargin: '500px', // No margin
          threshold: 0.0 // Trigger when 10% of the element is visible
      };

      const observer = new IntersectionObserver(this.handleIntersection, options);
      const target = document.getElementById('product-card'+this.$props.productId);
      if(target!=null){
          observer.observe(target);
      }else{
          this.getProduct();
          this.getProductImages();
      }

      if(window.innerWidth < 1349){
          this.mobileView = false;
      }
      window.addEventListener('resize', this.handleResize)
  },
  handleIntersection(entries, observer) {
    entries.forEach(entry => {
    if (entry.isIntersecting) {
            this.getProduct();
            this.getProductImages();
            observer.unobserve(entry.target);
      }
    /*else{
        this.product = {
            shopName: null,
            shopSlug: "/slug",
            productDtoId: -1,
            productDtoTile: "product title",
            productDtoPrice: 0.00
        }
        this.productImages = [];
        //
      }*/
    });
  },
  handleResize(){
    if(window.innerWidth < 1349){
      this.mobileView = true;
    }else{
      this.mobileView = false;
    }
  },
},
beforeUnmount() {
    if(this.product.productDtoId > 0){
        this.$store.commit('saveProductState', { id: this.product.productDtoId, componentState: this.$data })
    }
},
watch:{
  productId: function(id){
      this.product = {
          shopName: null,
          shopSlug: "/slug",
          productDtoId: -1,
          productDtoTile: "product title",
          productDtoPrice: 0.00
      }
      this.productImages = [];
      if(id > 0){
          const productState = this.$store.state.productStates[id];
          if(productState !== undefined){
              this.product = productState.product;
              this.productImages = productState.productImages;
          }else{
              this.setUp();
          }
      }
  }
},
    mounted(){
     // console.log(this.loaded);
        if(this.$props.productId > 0){
            const productState = this.$store.state.productStates[this.$props.productId];
            if(productState !== undefined){
                this.product = productState.product;
                this.productImages = productState.productImages;
            }else{
                this.setUp();
            }
        }

    //  console.log("mounted");
    }
}
</script>

<style scoped>
@media screen and (min-width: 1350px){
  .product-card {
      width: calc(2*var(--stripe) + var(--space));
      text-align: start;
  }

  .product-card-img {
      border: 1px solid var(--lightGray);
      width: calc(2*var(--stripe) + var(--space));
      height: calc(2*var(--stripe) + var(--space));
  }

  .product-card-info {
      margin-top: 10px;
  }

  svg {
      width: 20px;
      height: 20px;
  }

  #shop-name{
      margin-bottom: 6px;
  }

  #product-title{
      height: 24px;
      overflow: hidden;
      margin-bottom: 5px;
  }
}

@media screen and (max-width: 1349px) and (min-width: 744px){
  .product-card {
      width: calc(3*var(--tablet-stripe) + 2*var(--tablet-space));
      text-align: start;
  }

  .product-card-img {
      width: calc(3*var(--tablet-stripe) + 2*var(--tablet-space));
      height: calc(3*var(--tablet-stripe) + 2*var(--tablet-space));
  }

  .product-card-info {
      margin-top: 10px;
  }

  svg {
      width: 20px;
      height: 20px;
  }
  #shop-name{
      margin-bottom: 3px;
  }

  #product-title{
      margin-bottom: 5px;
  }

}

@media screen and (max-width: 743px) {
  .product-card {
      width: 100%;
      //width: calc(4*var(--mobile-stripe) + 3*var(--mobile-space));
      text-align: start;
  }

  .product-card-img {
      border: 1px solid var(--lightGray);
      width: 100%;
      height: 100%;
      //width: calc(4*var(--mobile-stripe) + 3*var(--mobile-space) - 2px);
      //height: calc(4*var(--mobile-stripe) + 3*var(--mobile-space) - 2px);
  }

  .product-card-info {
      margin-top: 10px;
  }

  svg {
      width: 20px;
      height: 20px;
  }

  #shop-name{
      margin-bottom: 3px;
  }

  #product-title{
      margin-bottom: 6px;
  }
}

</style>
