<template>
    <div class="shopcard" :class="{'map-card' : this.$props.mapPopUp, 'prev-card': this.$props.previewGrid}" :id="'shop-card'+this.$props.shopId" @mouseover="this.showArrows = true;" @mouseleave="this.showArrows=false;">
        <div  v-if="onlineOnly && showBanner" class="online-only-banner">Online only</div>
        <div v-if="productHighlights.length > 0">
            <div v-if="imageLimit && mobileView">
                <img  :src="productHighlights[0]"  class="shop-card-image" :class="{'map-img' : this.$props.mapPopUp, 'prev-grid': this.$props.previewGrid}">
            </div>
            <div v-else>
                <carousel
                        :items-to-show="1.0"
                        :wrapAround="true">
                    <slide v-for="(productHighlight, index) of productHighlights" :key="index">
                        <router-link class="text-dark text-decoration-none" :to="{name: 'ShopDetails', params: { id: this.shop.shopDtoSlug }}">
                            <img :src="productHighlight" :alt="shop.shopDtoSlug" class="shop-card-image" :class="{'map-img' : this.$props.mapPopUp, 'prev-grid': this.$props.previewGrid}">
                        </router-link>
                    </slide>
                    <template #addons>
                        <navigation v-if="showArrows || mobileView" />
                    </template>
                </carousel>
            </div>
        </div>
        <router-link class="text-dark text-decoration-none" :to="{name: 'ShopDetails', params: { id: this.shop.shopDtoSlug }}">
            <!--<img v-if="productHighlights.length === 0"
                 :src="imageURL"
                 class="shop-card-image"
                 :class="{'map-img' : this.$props.mapPopUp}"
                 :id="'shop-card-image_'+this.shopId">-->
            <div  v-if="productHighlights.length === 0" style="background-color: var(--lightGray); width: 100%;" class="shop-card-image" :class="{'map-img' : this.$props.mapPopUp,  'prev-grid': this.$props.previewGrid}"></div>
            <p v-if="shop.shopDtoName != null" class="shopcard-title heading-l">{{shop.shopDtoName}}</p>
            <div v-else style="background-color: var(--lightGray); height: 20px; width: 100%; margin: 2px;"></div>
            <p v-if="shop.shopDtoQuickDescription != null" class="shopcard-description heading-m">{{shop.shopDtoQuickDescription}}</p>
            <div v-else style="background-color: var(--lightGray); height: 20px; width: 100%; margin: 2px;"></div>
        </router-link>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import axios from "axios";
import { Carousel, Slide, Navigation } from 'vue3-carousel'
export default {
  name: "ShopCard",
  props: ["shopId", "index", "mapPopUp", "imageLimit", "previewGrid"],
  components: {Carousel, Slide, Navigation},
  data(){
    return{
      mobileView: false,
      showArrows: false,
      shop: {
          shopDtoId: -1,
          shopDtoName: null,
          shopDtoSlug: "no-slug",
          shopDtoQuickDescription: null,
      },
      productHighlights: [],
      onlineOnly: false,
      showBanner: false,
      imageURL: ""
    }
  },
  methods:{
      async checkOnlineOnly(){
          await axios({
              method: "get",
              url: this.$backendURL + "/shop/checkOnlineOnly/"+ this.shopId
          })
              .then(res => {
                  this.onlineOnly = res.data;
              })
              .catch(err => {
                  console.log(err)
              })
      },
      async getShopById(){
          await axios({
              methods: "get",
              url: this.$backendURL + "/shop/getShopById?shopId="+this.shopId
          })
              .then(res => {
                  this.shop = res.data;
              })
              .catch(err => {
                  console.log(err);
              })
      },
      /*async getSlider(){
          await axios({
              method: "get",
              url: this.$backendURL + "/product-highlight/getByShopId/"+this.$props.shopId
          })
              .then(res => {
                  this.productHighlights = [];
                  this.productHighlights =  res.data.slice(0, 2);
                  if(this.productHighlights.length>0){
                      this.imageURL = this.productHighlights[0].productHighlightDtoImageUrl;
                  }
              })
              .catch(err => {
                  console.log(err);
              })
      },*/
      async getSlider(){
          await axios({
              method: "get",
              url: this.$backendURL + "/product-highlight/getSlider/"+this.$props.shopId
          })
              .then(res => {
                  this.productHighlights = [];
                  this.productHighlights =  res.data.slice(0, 5);
              })
              .catch(err => {
                  console.log(err);
              })
      },
      handleIntersection(entries, observer) {
          entries.forEach(entry => {
              if (entry.isIntersecting) {
                      this.getShopById();
                      this.checkOnlineOnly();
                      this.getSlider();
                  if(window.outerWidth<1350) {
                      this.showBanner = true;
                  }
                  observer.unobserve(entry.target);
              }else{
                  //
                  /*this.shop = {
                      shopDtoId: -1,
                      shopDtoName: null,
                      shopDtoSlug: "no-slug",
                      shopDtoQuickDescription: null,
                  }
                  this.productHighlights = [];*/
              }
          });
      },
    handleResize(){
      if(window.innerWidth < 1349){
        this.mobileView = true;
      }else{
        this.mobileView = false;
      }
    },
    setUp(){
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '500px', // No margin
            threshold: 0.0 // Trigger when 10% of the element is visible
        };

        const observer = new IntersectionObserver(this.handleIntersection, options);
        const target = document.getElementById('shop-card'+this.$props.shopId);
        if(target != null){
            observer.observe(target);
        }else{
            this.getShopById();
            this.checkOnlineOnly();
            this.getSlider();
        }
        if(window.innerWidth < 1349){
            this.mobileView = false;
        }
        window.addEventListener('resize', this.handleResize)
    }
  },
    beforeUnmount() {
      if(this.shop.shopDtoId > 0){
          this.$store.commit('saveShopState', { id: this.shop.shopDtoId , componentState: this.$data })
      }
    },
    watch: {
    shopId: function (id) {
      this.shop = {
          shopDtoId: -1,
          shopDtoName: null,
          shopDtoSlug: "no-slug",
          shopDtoQuickDescription: null,
      }
      this.productHighlights = [];
      if(id > 0){
          const shopState = this.$store.state.shopStates[this.$props.shopId];
          if(shopState !== undefined){
              this.shop = shopState.shop;
              this.productHighlights = shopState.productHighlights;
          }else{
              this.setUp();
          }
      }
    }
  },
   mounted() {
      this.handleResize();
      if(this.$props.shopId > 0){
          const shopState = this.$store.state.shopStates[this.$props.shopId];
          if(shopState !== undefined){
              this.shop = shopState.shop;
              this.productHighlights = shopState.productHighlights;
          }else{
              this.setUp();
          }
      }
  }
}

</script>

<style scoped>

@media screen and (min-width: 1350px){
  .shopcard{
    overflow: hidden;
    min-width: calc(2*var(--stripe) + 1*var(--space));
    max-width: calc(2*var(--stripe) + 1*var(--space));
    min-height: calc(2*var(--stripe) + 3.25*var(--space));
    height: auto;
    margin-bottom: var(--space);
    position: relative;

  }

  .shop-card-arrows{
      width: calc(2*var(--stripe) + 1*var(--space));
      height: var(--space);
      position: absolute;
      z-index: 1;
      margin-top: calc(1*var(--stripe));
      display: flex;
      justify-content: space-between;
      padding-left: calc(0.5*var(--space));
      padding-right: calc(0.5*var(--space));
  }

  .shop-card-arrow-seperator{
      height: var(--space);
      width:  calc(2*var(--stripe) - 2*var(--space));
  }

  .shop-card-image{
    min-height: calc(2*var(--stripe) + 1*var(--space));
    min-width: calc(2*var(--stripe) + 1*var(--space));
    max-height: calc(2*var(--stripe) + 1*var(--space));
    max-width: calc(2*var(--stripe) + 1*var(--space));
    overflow: hidden;
    transition: 300ms ease-in-out ;
      margin-bottom: 7px;
  }

  .shopcard-title{
    padding: 0;
    margin:0;
    text-align: start;
    margin-bottom: 5px;
  }

  .shopcard-description{
    text-align: start;
    padding: 0;
    margin:0;
  }

  .shopcard-text{
    font-size: 0.75rem;
  }

  .overlay-buttons:hover{
    opacity: 0.8;
  }

  .prevButton {
      opacity: 0;
      cursor: pointer;
      width: var(--space);
      height: var(--space);
  }
  .nextButton{
    opacity: 0;
    cursor: pointer;
    width: var(--space);
    height: var(--space);
    rotate: 180deg;
  }

  .image-row{
      display: grid;
      align-items: center;
  }

  .online-only-banner{
      position: absolute;
      font-size: 0.8rem;
      left: 0;
      top: 0;
      z-index: 1;
      padding: 3px;
      background: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (max-width: 1349px) and (min-width: 744px){
    .shopcard{
        overflow: hidden;
        width: 100%;
        margin-bottom: var(--tablet-space);
        position: relative;
    }

    .shop-card-image{
        width: calc(3*var(--tablet-stripe) + 2*var(--tablet-space));
        height: calc(3*var(--tablet-stripe) + 2*var(--tablet-space));
        overflow: hidden;
        transition: 300ms ease-in-out;
        margin-bottom: 7px;
    }

    .shopcard-title{
        padding: 0;
        margin:0;
        text-align: start;
        margin-bottom: 5px;
    }

    .shopcard-description{
        text-align: start;
        padding: 0;
        margin:0;
    }

    .shopcard-text{
        font-size: 0.75rem;
    }

    .overlay-buttons:hover{
        opacity: 0.8;
    }

    .prevButton {
        opacity: 1;
        cursor: pointer;
        width: var(--tablet-space);
        height: var(--tablet-space);
    }
    .nextButton{
        opacity: 1;
        cursor: pointer;
        width: var(--tablet-space);
        height: var(--tablet-space);
        rotate: 180deg;
    }

    .image-row{
        display: grid;
        align-items: center;
    }

    .online-only-banner{
        position: absolute;
        font-size: 0.8rem;
        left: 0;
        top: 0;
        z-index: 1;
        padding: 3px;
        background: rgba(255, 255, 255, 0.5);
    }
}


@media screen and (max-width: 743px){

  .shopcard{
      width: 100%;
      text-align: start;
  }

  .shop-card-image{
      border: 1px solid var(--lightGray);
      width: calc(4*var(--mobile-stripe) + 3*var(--mobile-space) - 2px);
      height: calc(4*var(--mobile-stripe) + 3*var(--mobile-space) - 2px);
  }

  .prev-card{
    width: calc(3*var(--mobile-stripe) + 2*var(--mobile-space)) !important;
  }

  .prev-grid{
    width: calc(3*var(--mobile-stripe) + 2*var(--mobile-space)) !important;
    height: calc(3*var(--mobile-stripe) + 2*var(--mobile-space)) !important;
  }

  .map-card{
    width: calc(2*var(--mobile-stripe) + 1*var(--mobile-space)) !important;
    margin-bottom: var(--mobile-space) !important;
  }

  .map-img{
    height: calc(2*var(--mobile-stripe) + 1*var(--mobile-space)) !important;
    width: calc(2*var(--mobile-stripe) + 1*var(--mobile-space)) !important;
  }

  .shopcard-title{
    padding: 0;
    margin:0;
    text-align: start;
    margin-bottom: 5px;
  }

  .shopcard-description{
    text-align: start;
    padding: 0;
    margin:0;
  }

  .prevButton {
    opacity: 0.3;
    cursor: pointer;
    width: calc(3*var(--mobile-space));
    height: calc(3*var(--mobile-space));
  }
  .nextButton{
    opacity: 0.3;
    cursor: pointer;
    width: calc(3*var(--mobile-space));
    height: calc(3*var(--mobile-space));
    rotate: 180deg;
  }

  .online-only-banner{
      position: absolute;
      font-size: 0.8rem;
      left: var(--mobile-edge);
      z-index: 1;
      padding: 3px;
      background: rgba(255, 255, 255, 0.5);
  }
}



</style>