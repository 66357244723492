<template>
    <div class="ppg-container">
        <div class="ppg-top-row">
            <div class="heading-home">
                {{title}}
            </div>
        </div>
        <div class="city-slider">
            <img v-if="items > 1.5" src="../../assets/icons/Arrow_go_back.svg" class="icon" @click="currentSlide--" alt="arrow left">
            <div class="ppg-scroll-container">
                <carousel
                    :items-to-show="items"
                    :wrapAround="true"
                    :snapAlign="align"
                v-model="currentSlide">
                    <slide :key="0">
                        <router-link to="/products" class="nav-link" @click="setCity('Berlin')">
                            <img src="../../assets/Cities/Berlin.jpg" alt="Berlin" class="city-img">
                            <div class="body-l">Berlin</div>
                        </router-link>
                    </slide>
                    <slide :key="1">
                        <router-link to="/products" class="nav-link" @click="setCity('Hamburg')">
                            <img src="../../assets/Cities/Hamburg.jpg" alt="Hamburg" class="city-img">
                            <div class="body-l">Hamburg</div>
                        </router-link>
                    </slide>
                    <slide :key="2">
                        <router-link to="/products" class="nav-link" @click="setCity('Lisbon')">
                            <img src="../../assets/Cities/Lisbon.jpg" alt="Lisbon" class="city-img">
                            <div class="body-l">Lisbon</div>
                        </router-link>
                    </slide>
                    <slide :key="3">
                        <router-link to="/products" class="nav-link" @click="setCity('Valletta')">
                            <img src="../../assets/Cities/Valletta.jpg" alt="Barcelona" class="city-img">
                            <div class="body-l">Valletta</div>
                        </router-link>
                    </slide>
                    <!--<slide :key="3">
                        <router-link to="/products" class="nav-link" @click="setCity('Barcelona')">
                            <img src="../../assets/Cities/Barcelona.jpg" alt="Barcelona" class="city-img">
                            <div class="body-l">Barcelona</div>
                        </router-link>
                    </slide>
                    <slide :key="4">
                        <router-link to="/products" class="nav-link" @click="setCity('Barcelona')">
                            <img src="../../assets/Cities/Barcelona.jpg" alt="Barcelona" class="city-img">
                            <div class="body-l">Munich</div>
                        </router-link>
                    </slide>
                    <slide :key="6">
                        <router-link to="/products" class="nav-link" @click="setCity('Barcelona')">
                            <img src="../../assets/Cities/Barcelona.jpg" alt="Barcelona" class="city-img">
                            <div class="body-l">Rome</div>
                        </router-link>
                    </slide>-->
                </carousel>
            </div>
            <img v-if="items > 1.5" src="../../assets/icons/Arrow_go_back.svg" id="right-arrow" class="icon" @click="currentSlide++" alt="arrow right">
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide} from 'vue3-carousel'

export default {
    name: "CityPreviewSlider",
    components: {Carousel, Slide},
    props: ["title"],
    data(){
        return{
            align: "start",
            items: 4.0,
            currentSlide: 0,
            cities: [
                {
                    city: "Berlin",
                    src: "../../assets/Cities/Berlin.jpg"
                },
                {
                    city: "Hamburg",
                    src: "../../assets/Cities/Hamburg.jpg"
                },
                {
                    city: "Lisbon",
                    src: "../../assets/Cities/Lisbon.jpg"
                },
                {
                    city: "Barcelona",
                    src: "../../assets/Cities/Barcelona.jpg"
                }
            ]
        }
    },
    methods:{
        clearFilters(){
            this.$cookies.set('sm_city', 'All cities');
            this.$cookies.set('sm_category', [12, 13, 14, 15]);
            this.$cookies.set('sm_minPrice', 0);
            this.$cookies.remove('sm_maxPrice');
            this.$cookies.remove("sm_QsL")
            this.$cookies.remove("sm_selGen")
        },
        setCity(city){
            this.clearFilters();
            this.$cookies.set('sm_city', city);
        },
        handleResize(){
            if(window.innerWidth > 1350){
                this.items = 4.0;
                this.align = "start";
            }else if(window.innerWidth > 744 && window.innerWidth < 1350){
                this.items = 2.0;
                this.align = "start";
            }else{
                this.items = 1.25;
                this.align = "center";
            }
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        console.log(this.cities)
    }
}
</script>

<style scoped>

#right-arrow{
    transform: rotate(180deg);
}

.icon{
    cursor: pointer;
    margin-top: -40px;
}

.ppg-top-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ppg-grid{
    display: grid;
}

.ppg-scroll-container{
    width: 100%;
}

.city-slider{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}


@media screen and (min-width: 1350px){
    .ppg-scroll-container{
        width: calc(8*var(--stripe) + 10*var(--space));
        margin: auto;
    }

    .ppg-grid {
        grid-template-columns: repeat(4, calc(2*var(--stripe) + var(--space)));
        grid-column-gap: calc(2*var(--space));
    }

    .ppg-top-row{
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .city-img{
        height: calc(2*var(--stripe) + var(--space));
        border-radius: 100%;
        margin-bottom: var(--space);
    }
}

@media screen and (min-width: 744px) and (max-width: 1349px){

    .ppg-grid {
        grid-template-columns: repeat(4, calc(3*var(--tablet-stripe) + 2*var(--tablet-space )));
        grid-column-gap: var(--space);
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .city-img{
        height: calc(3*var(--tablet-stripe) + 2*var(--tablet-space ));
        border-radius: 100%;
        margin-bottom: var(--space);
    }
}

@media screen and (max-width: 743px){

    .ppg-grid {
        grid-template-columns: repeat(4, calc(3*var(--mobile-stripe) + 2*var(--mobile-space)));
        grid-column-gap: var(--mobile-space);
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .city-img{
        height: calc(3*var(--mobile-stripe) + 2*var(--mobile-space));
        border-radius: 100%;
        margin-bottom: var(--space);
    }

}
</style>