<template>
    <nav class="topnav-wrapper" :class="{'transparent': isTransparent}">
        <div class="topnav" :class="{'transparent': isTransparent}" id="myTopnav">
            <div id="topnav-logo">
                <router-link class="nav-link" to="/" @click="removeActive">
                    <img v-if="isTransparent" src="../assets/small-shops-logo-white.svg" alt="small-shops">
                    <img v-else src="../assets/small-shops-logo.svg" alt="small-shops">
                </router-link>
            </div>
            <div id="main-items" :class="{'transparent': isTransparent && !mobileView}"
                 v-if="this.$route.path != '/login' && this.$route.path != '/register' && this.$route.name != 'ResetPassword'">
                <router-link to="/products" class="main-item" @click="sendUserAction('HomeMenu');">
          <span @mouseover="indicateMenu($event)" @mouseleave="removeIndicator"
                :class="{'transparent': isTransparent && !mobileView}">
            Products
          </span>
                </router-link>
                <router-link to="/locations" class="main-item">
          <span @mouseover="indicateMenu($event)" @mouseleave="removeIndicator"
                :class="{'transparent': isTransparent && !mobileView}">
            Shops
          </span>
                </router-link>
                <router-link to="/founders-qa" class="main-item">
          <span @mouseover="indicateMenu($event)" @mouseleave="removeIndicator"
                :class="{'transparent': isTransparent && !mobileView}">
            Designers Q&A
          </span>
                </router-link>
            </div>
            <div id="sliding-menu-indicator-hover" v-if="!mobileView"></div>
            <div id="sliding-menu-indicator" v-if="!mobileView"></div>
            <div id="secondary-items"
                 v-if="this.$route.path != '/login' && this.$route.path != '/register' && this.$route.name != 'ResetPassword'">
                <router-link to="/register" @click="removeActive" v-if="false">
                    <button class="register-button"
                            :class="{'mobile-transparent-button' : mobileView && isTransparent, 'mobile-transparent-button-black': mobileView && !isTransparent}"></button>
                </router-link>
                <router-link to="/login" class="button-transparent" :class="{'mobile-transparent-button' : mobileView && isTransparent,
                'mobile-transparent-button-black': mobileView && !isTransparent}" @click="removeActive"
                             v-if="false">
                    Login
                </router-link>
                <router-link to="/account" v-if="authentication == true">
                    <img id="account_icon"
                         class="icon"
                         :src="accountIconSrc"
                         alt="account icon"
                         @mouseenter="getAccountIconSrc(true)"
                         @mouseleave="getAccountIconSrc(false)">
                </router-link>
                <router-link to="/login" v-else>
                    <img id="account_icon"
                         class="icon"
                         :src="accountIconSrc"
                         alt="account icon"
                         @mouseenter="getAccountIconSrc(true)"
                         @mouseleave="getAccountIconSrc(false)">
                </router-link>
                <router-link to="/wishlist" class="nav-link">
                    <img class="icon wishlist_icon"
                         :src="wishlistIconSrc"
                         alt="wishlist icon"
                         @mouseenter="getWishlistIconSrc(true)"
                         @mouseleave="getWishlistIconSrc(false)">
                </router-link>
                <router-link to="/cart" class="nav-link" @mouseenter="displayCartPopUp" @mouseleave="clearCartPopUp">
                    <img class="icon cart_icon"
                         :src="cartIconSrc"
                         alt="cart icon"
                         @mouseenter="getCartIconSrc(true)"
                         @mouseleave="getCartIconSrc(false)">
                    <div id="nav_cart_count" class="cart-count label-m" :class="{'transparent': isTransparent}"
                         v-if="this.$route.path != '/cart'">{{ cartCount }}
                    </div>
                    <div id="nav_cart_count_hover" class="cart-count label-m"
                         v-if="this.$route.path == '/cart'">{{ cartCount }}
                    </div>
                </router-link>
            </div>
            <img alt="close" src="../assets/icons/Close_Register.svg" class="icon" id="login-back"
                 v-if="this.$route.path == '/login' || this.$route.path == '/register' || this.$route.name == 'ResetPassword'"
                 @click="this.$router.go(-1);">
        </div>
        <div class="menu-second-row">
            <div class="mobile-menu"
                 v-if="this.$route.path != '/login' && this.$route.path != '/register' && this.$route.name != 'ResetPassword'">
                <input type="checkbox" @click="expandNav" class="menu-open" name="menu-open" id="menu-open">
                <label class="menu-open-button" for="menu-open">
                    <span class="lines line-1" :class="{'white-line': isTransparent}"></span>
                    <span class="lines line-2" :class="{'white-line': isTransparent}"></span>
                    <span class="lines line-3" :class="{'white-line': isTransparent}"></span>
                    <div style="background-color: transparent; position: absolute; width: 25px; height: 25px; margin-top: -15px;"></div>
                </label>
            </div>
            <div class="searchbar-wrapper"
                 v-if="this.$route.path != '/login' && this.$route.path != '/register' && this.$route.name != 'ResetPassword'">
                <input v-if="!mobileView" type="text" placeholder="Search for products or locations of small shops"
                       class="searchbar-input" v-model="searchPrompt" @keyup.enter="searchProducts">
                <input v-else type="text" placeholder="Search for products or locations" class="searchbar-input"
                       v-model="searchPrompt">
                <div class="search-button" @click="searchProducts">
                    <img class="icon" src="../assets/icons/Search.svg" alt="Search">
                </div>
            </div>
        </div>

        <hr class="horizontal-line" v-if="!isTransparent"/>
    </nav>
    <CartPopUp v-if="showCartPopUp" @mouseover="displayCartPopUp" @mouseleave="clearCartPopUp"
               @close="this.showCartPopUp=false; clearTimeout(this.cartTimeOut);"></CartPopUp>
</template>

<script>
import axios from 'axios';
import CartPopUp from "@/components/Shop/CartPopUp.vue";

export default {
    name: "ShopNavigation",
    components: {CartPopUp},
    data() {
        return {
            accountIconSrc: null,
            cartIconSrc: null,
            wishlistIconSrc: null,
            accountHover: false,
            cartHover: false,
            wishlistHover: false,
            token: null,
            authentication: false,
            role: null,
            cartCount: 0,
            showCartPopUp: false,
            cartTimeOut: null,
            mobileView: false,
            isTransparent: true,
            searchPrompt: "",
            cities: ["Berlin", "Hamburg", "Lisbon", "Munich", "Cologne", "Barcelona", "Milan", "Paris", "Rome", "Valetta"],
            lc_cities: ["berlin", "hamburg", "lisbon", "munich", "cologne", "barcelona", "milan", "paris", "rome", "valetta"]
        }
    },
    props: ["baseURL"],
    methods: {
        async sendUserAction(action, note){
            let url = this.$backendURL + "/action/send?action="+action
            if(localStorage.getItem('token') != null){
              url += "&authenticationToken=" + localStorage.getItem('token')
            }
            if(note != null){
              url += "&note=" + note
            }
            await axios.post(url)
                .then(res => {
                  console.log(res);
                })
                .catch(err => {
                  console.error(err);
                })
        },
        async searchProducts() {
            let prompt_elements = this.searchPrompt.split(" ");
            let locations = false;
            let detected_cities = "All cities";
            for (const elem of prompt_elements) {
                if (this.lc_cities.includes(elem.toLowerCase())) {
                    locations = true;
                    detected_cities = this.cities.at(this.lc_cities.indexOf(elem.toLowerCase()));
                    break;
                }
            }
            this.$cookies.set('sm_city', detected_cities);
            if (locations) {
                this.$router.push("/locations?search=" + this.searchPrompt);
            } else {
                this.$router.push("/products?search=" + this.searchPrompt);
            }
            if(this.$route.path === '/'){
              this.sendUserAction('HomeSearch');
            }

            this.sendUserAction('SearchFilter', 'Search: ' + this.searchPrompt);
        },
        getAccountIconSrc(hover) {
            if (hover && this.isTransparent) {
                this.accountIconSrc = require('../assets/icons/Account_Hover_White.svg');
            } else if ((hover && !this.isTransparent) || this.$route.path === "/account") {
                this.accountIconSrc = require('../assets/icons/Account_Hover.svg');
            } else if (!hover && this.isTransparent) {
                this.accountIconSrc = require('../assets/icons/Account_White.svg');
            } else {
                this.accountIconSrc = require('../assets/icons/Account.svg');
            }
        },
        getCartIconSrc(hover) {
            if (hover && this.isTransparent) {
                this.cartIconSrc = require('../assets/icons/Cart_Hover_White.svg');
            } else if ((hover && !this.isTransparent) || this.$route.path === "/cart") {
                this.cartIconSrc = require('../assets/icons/Cart_Hover.svg');
            } else if (!hover && this.isTransparent) {
                this.cartIconSrc = require('../assets/icons/Cart_White.svg');
            } else {
                this.cartIconSrc = require('../assets/icons/Cart.svg');
            }
        },
        getWishlistIconSrc(hover) {
            if (hover && this.isTransparent) {
                this.wishlistIconSrc = require('../assets/icons/Wishlist_Hover_White.svg');
            } else if ((hover && !this.isTransparent) || this.$route.path === "/wishlist") {
                this.wishlistIconSrc = require('../assets/icons/Wishlist_Hover.svg');
            } else if (!hover && this.isTransparent) {
                this.wishlistIconSrc = require('../assets/icons/Wishlist_White.svg');
            } else {
                this.wishlistIconSrc = require('../assets/icons/Wishlist.svg');
            }
        },
        displayCartPopUp() {
            let vm = this;
            if (!this.showCartPopUp) {
                this.cartTimeOut = setTimeout(function () {
                    vm.showCartPopUp = true;
                }, 500)
            } else {
                clearTimeout(this.cartTimeOut);
            }
        },
        clearCartPopUp() {
            let vm = this;
            if (this.showCartPopUp) {
                this.cartTimeOut = setTimeout(function () {
                    vm.showCartPopUp = false;
                }, 4000)
            } else {
                clearTimeout(this.cartTimeOut);
            }
        },
        async authenticateToken() {
            if (this.token == null) {
                this.authentication = false;
                this.role = "locked"
            } else {
                await axios({
                    method: 'get',
                    url: this.$backendURL + "/user/" + this.token
                })
                    .then(res => {
                        if (res.data.userLocked) {
                            this.authentication = false;
                            this.role = "locked"
                        } else {
                            this.authentication = true
                            this.role = res.data.userRole
                        }

                    })
                    .catch(err => {
                        localStorage.removeItem("token")
                        console.error(err)
                    })
            }
            this.getCartCount();
        },
        async getCartCount() {
            let url = this.$backendURL + "/cart/get-count?device_id=" + localStorage.getItem('device_id');
            if (this.token != null) {
                url += "&authenticationToken=" + this.token;
            }
            await axios.get(url)
                .then(res => {
                    this.cartCount = res.data;
                })
        },
        expandNav() {
            let x = document.getElementById("myTopnav");
            let mainItems = document.getElementById("main-items");
            if (!x.classList.contains("responsive")) {
                x.classList.add("responsive");
                mainItems.classList.add("responsive");
            } else {
                x.classList.remove("responsive");
                mainItems.classList.remove("responsive");
            }
        },
        indicateMenu(element) {
            let width = element.target.offsetWidth;
            let left = element.target.getBoundingClientRect();
            let sliding_indicator = document.getElementById('sliding-menu-indicator-hover');
            if (sliding_indicator != null) {
                sliding_indicator.style.display = "block";
                sliding_indicator.style.width = width + "px";
                sliding_indicator.style.left = left.left + "px";
            }
        },
        removeIndicator() {
            let element = document.getElementById('sliding-menu-indicator-hover');
            if (element != null) {
                element.style.display = "none";
            }
        },
        activeMenu(element) {
            let width = element.target.offsetWidth;
            let left = element.target.getBoundingClientRect();
            if (element.target.localName === "a") {
                width = element.target.firstChild.offsetWidth;
                left = element.target.firstChild.getBoundingClientRect();
            }
            let sliding_indicator = document.getElementById('sliding-menu-indicator');
            if (sliding_indicator != null) {
                sliding_indicator.style.display = "block";
                sliding_indicator.style.width = width + "px";
                sliding_indicator.style.left = left.left + "px";
            }
        },
        removeActive() {
            let element = document.getElementById('sliding-menu-indicator')
            if (element != null) {
                element.style.display = "none";
            }
        },
        activeCurrent() {
            if (window.innerWidth > 744) {
                this.mobileView = false;
            } else {
                this.mobileView = true;
            }
            let elements = document.getElementsByClassName("main-item")
            let current = null;
            for (const element of elements) {
                if (element.attributes.href.value === this.$route.path) {
                    current = element;
                }
            }
            if (current != null) {
                let width = current.offsetWidth;
                let left = current.getBoundingClientRect();
                if (current.localName === "a") {
                    width = current.firstChild.offsetWidth;
                    left = current.firstChild.getBoundingClientRect();
                }
                let sliding_indicator = document.getElementById('sliding-menu-indicator');
                if (sliding_indicator != null) {
                    sliding_indicator.style.display = "block";
                    sliding_indicator.style.width = width + "px";
                    sliding_indicator.style.left = left.left + "px";
                }
            }
        },
        closeMobileMenu() {
            let x = document.getElementById("myTopnav");
            let mainItems = document.getElementById("main-items");
            let checkbox = document.getElementById('menu-open');
            if (x != null && mainItems != null && checkbox != null) {
                x.className = "topnav";
                mainItems.className = " ";
                checkbox.checked = false;
            }
        },
        handleScroll() {
            this.isTransparent = window.scrollY < 300 && this.$route.fullPath === "/";
            this.getAccountIconSrc(false);
            this.getCartIconSrc(false);
            this.getWishlistIconSrc(false);
        }
    },
    mounted() {
        this.getAccountIconSrc(false);
        this.getCartIconSrc(false);
        this.getWishlistIconSrc(false);
        this.token = localStorage.getItem('token');
        this.authenticateToken()
        window.addEventListener('resize', this.activeCurrent);
        window.addEventListener('scroll', this.handleScroll);
        this.isTransparent = true;
        this.handleScroll();
    },
    watch: {
        '$route'() {
            this.getAccountIconSrc(false);
            this.getCartIconSrc(false);
            this.getWishlistIconSrc(false);
            window.addEventListener('resize', this.activeCurrent);
            window.addEventListener('scroll', this.handleScroll);
            this.token = localStorage.getItem('token');
            this.authenticateToken();
            this.activeCurrent();
            this.closeMobileMenu();
            this.mobileView = window.innerWidth < 744;
            this.isTransparent = true;
            this.handleScroll();
        }
    }
}
</script>

<style scoped>

.button-transparent {
    background-color: white;
}

.transparent {
    background-color: transparent !important;
    color: white !important;
}

.white-line {
    background: white !important;
}

.mobile-transparent-button {
    border: none !important;
    font-weight: 400 !important;
    background-color: transparent !important;
    color: white !important;
}

.mobile-transparent-button-black {
    border: none !important;
    font-weight: 400 !important;
    background-color: transparent !important;
    color: black !important;
}


.searchbar-wrapper {
    margin: auto;
    display: flex;
    padding: 2px;
    border: 1px solid var(--darkGray);
    border-radius: 50px;
    justify-content: end;
    margin-bottom: 20px;
    background-color: white;
}

.searchbar-input {
    border-radius: 50px;
    border: none;
    background-color: white;
    color: black;
    padding-left: 15px;
}

.searchbar-input:focus {
    border: none;
    outline: none;
}

.search-button {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 50px;
    background-color: var(--success);
    cursor: pointer;
}


@media screen and (min-width: 1350px) {
    .searchbar-wrapper {
        width: calc(4 * var(--stripe) + 3 * var(--space));
    }

    .searchbar-input {
        width: calc(4 * var(--stripe) + 3 * var(--space) - 40px);
    }

    .cart-count {
        position: absolute;
        top: 61px;
        z-index: 1;
        color: black;
        margin-left: 11.5px;
    }

    #login-back {
        width: 30px;
        height: 30px;
        position: absolute;
        right: var(--edge);
        top: 48px;
        cursor: pointer;
    }

    .topnav-wrapper {
        background-color: white;
        position: fixed;
        top: 0;
        z-index: 2;
    }

    #main-menu-v-line {
        width: 1px;
        height: 1.25rem;
        background-color: var(--darkGray);
        margin-bottom: -0.125rem;
        opacity: 0.8;
    }

    .topnav {
        display: grid;
        grid-template-columns: calc(2 * var(--stripe) + 0.5 * var(--space) + 0 * var(--edge)) calc(6 * var(--stripe) + 8 * var(--space) + 0 * var(--edge)) calc(2 * var(--stripe) + 0.5 * var(--space) + 0 * var(--edge));
        padding-left: var(--edge);
        padding-right: var(--edge);
        padding-bottom: 30px;
        padding-top: 30px;
        margin: 0;
        position: relative;
    }

    #topnav-logo {
        justify-content: start;
        width: 136px;
    }

    #main-items {
        margin-top: auto;
        display: flex;
        justify-content: center;
        margin-bottom: -1px;
        width: 100%;
    }

    .main-item {
        text-decoration: none;
        color: black;
        padding: 0;
        margin: 0;
        font-size: 1rem;
    }

    .main-item:hover {
        opacity: 1;
    }

    #sliding-menu-indicator-hover {
        display: none;
        height: 2px;
        border-radius: 10px;
        background-color: #A7A7A7;
        position: absolute;
        top: 101px;
    }

    #sliding-menu-indicator {
        display: none;
        height: 2px;
        border-radius: 10px;
        background-color: #000000;
        position: absolute;
        top: 101px;
    }

    #main-items a {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    #secondary-items {
        margin: auto;
        margin-bottom: -7px;
        margin-right: 0;
        display: flex;
        justify-content: end;
        gap: 10px;
        align-items: baseline;
    }

    .topnav a:hover {
        color: black;
    }

    .topnav a.active {
        background-color: #04AA6D;
        color: white;
    }

    .mobile-menu {
        display: none;
    }


    .cart_icon:hover ~ .cart-count {
        color: white;
    }

    #nav_cart_count_hover {
        color: white;
    }


    .mobile-horizontal-line {
        display: none;
    }

    #secondary-mobile-items {
        display: none;
    }

}

@media screen and (max-width: 1349px) and (min-width: 744px) {
    .searchbar-wrapper {
        width: calc(5 * var(--tablet-stripe) + 4 * var(--tablet-space));
    }

    .searchbar-input {
        width: calc(5 * var(--tablet-stripe) + 4 * var(--tablet-space) - 40px);
    }

    .cart-count {
        position: absolute;
        top: 61px;
        z-index: 1;
        color: black;
        margin-left: 11.5px;
    }

    #login-back {
        width: 30px;
        height: 30px;
        position: absolute;
        right: var(--tablet-edge);
        top: 48px;
        cursor: pointer;
    }

    .topnav-wrapper {
        background-color: white;
        position: fixed;
        top: 0;
        z-index: 2;
    }

    #main-menu-v-line {
        width: 1px;
        height: 1.25rem;
        background-color: var(--darkGray);
        margin-bottom: -0.125rem;
        opacity: 0.8;
    }

    .topnav {
        display: grid;
        grid-template-columns: calc(2 * var(--tablet-stripe) + 2.5 * var(--tablet-space)) calc(5 * var(--tablet-stripe) + 3 * var(--tablet-space)) calc(2 * var(--tablet-stripe) + 2.5 * var(--tablet-space));
        padding-left: var(--tablet-edge);
        padding-right: var(--tablet-edge);
        padding-bottom: 30px;
        padding-top: 30px;
        margin: 0;
        position: relative;
    }

    #topnav-logo {
        justify-content: start;
        width: 136px;
    }

    #main-items {
        margin-top: auto;
        display: flex;
        justify-content: center;
        margin-bottom: -1px;
        width: 100%;
    }

    .main-item {
        text-decoration: none;
        color: black;
        padding: 0;
        margin: 0;
        font-size: 1rem;
    }

    .main-item:hover {
        opacity: 1;
    }

    #sliding-menu-indicator-hover {
        display: none;
        height: 2px;
        border-radius: 10px;
        background-color: #A7A7A7;
        position: absolute;
        top: 101px;
    }

    #sliding-menu-indicator {
        display: none;
        height: 2px;
        border-radius: 10px;
        background-color: #000000;
        position: absolute;
        top: 101px;
    }

    #main-items a {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    #secondary-items {
        margin: auto;
        margin-bottom: -7px;
        margin-right: 0;
        display: flex;
        justify-content: end;
        gap: 10px;
        align-items: baseline;
    }

    .topnav a:hover {
        color: black;
    }

    .topnav a.active {
        background-color: #04AA6D;
        color: white;
    }

    .mobile-menu {
        display: none;
    }


    .cart_icon:hover ~ .cart-count {
        color: white;
    }

    #nav_cart_count_hover {
        color: white;
    }


    .mobile-horizontal-line {
        display: none;
    }

    #secondary-mobile-items {
        display: none;
    }

}

@media screen and (max-width: 743px) {
    .menu-second-row {
        display: flex;
        justify-content: start;
        margin-left: var(--mobile-edge);
        gap: var(--mobile-space);
        align-items: center;
        margin-bottom: var(--mobile-space);
    }

    .searchbar-wrapper {
        margin-right: var(--mobile-edge);
        width: calc(3.5 * var(--mobile-stripe) + 3 * var(--mobile-space));
        margin-bottom: 0px;
    }

    .searchbar-input {
        width: calc(3.5 * var(--mobile-stripe) + 3 * var(--mobile-space) - 40px);
    }

    .topnav-wrapper {
        width: 100vw;
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        background-color: white;
    }

    .topnav {
        padding-bottom: 15px;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: var(--mobile-edge);
        padding-right: var(--mobile-edge);
    }

    #main-items {
        display: none;
    }

    #topnav-logo {
        display: block;
        z-index: 6;
        width: 136px;
    }


    .cart-count {
        position: relative;
        top: -19px;
        text-align: center;
    }

    .menu-item,
    .menu-open-button {
        border: none;
        color: #FFFFFF;
        text-align: center;
        line-height: 80px;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: -webkit-transform ease-out 200ms;
        transition: -webkit-transform ease-out 200ms;
        transition: transform ease-out 200ms;
        right: var(--mobile-edge);
    }

    .menu-open {
        display: none;
    }

    .mobile-menu {
        margin-top: -7px;
    }

    .lines {
        width: 24.35px;
        height: 1px;
        background: black;
        display: block;
        -webkit-transition: -webkit-transform 200ms;
        transition: -webkit-transform 200ms;
        transition: transform 200ms;
    }

    .line-1 {
        -webkit-transform: translate3d(0, -6px, 0);
        transform: translate3d(0, -6px, 0);
    }

    .line-2 {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .line-3 {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
    }

    .menu-open:checked + .menu-open-button .line-1 {
        -webkit-transform: translate3d(0, 1px, 0) rotate(45deg);
        transform: translate3d(0, 1px, 0) rotate(45deg);
    }

    .menu-open:checked + .menu-open-button .line-2 {
        -webkit-transform: translate3d(0, 0, 0) scale(0.0, 1);
        transform: translate3d(0, 0, 0) scale(0.0, 1);
    }

    .menu-open:checked + .menu-open-button .line-3 {
        -webkit-transform: translate3d(0, -1px, 0) rotate(-45deg);
        transform: translate3d(0, -1px, 0) rotate(-45deg);
    }

    .menu-open-button {
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition-duration: 400ms;
        transition-duration: 400ms;
        -webkit-transform: scale(1.0, 1.0) translate3d(0, 0, 0);
        transform: scale(1.0, 1.0) translate3d(0, 0, 0);
        cursor: pointer;
    }

    .menu-open:checked + .menu-open-button {
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
        -webkit-transition-duration: 200ms;
        transition-duration: 200ms;
        -webkit-transform: scale(1.0, 1.0) translate3d(0, 0, 0);
        transform: scale(1.0, 1.0) translate3d(0, 0, 0);
    }

    .menu-open:checked ~ .menu-item {
        -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
        transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    }

    #secondary-items {
        margin: auto;
        margin-bottom: -7px;
        margin-right: 0;
        display: flex;
        justify-content: end;
        gap: 10px;
        align-items: baseline;
    }

    #main-items.responsive {
        display: flex;
        flex-flow: column;
        gap: 15px;
        background-color: var(--lightGray);
        height: fit-content;
        color: black;
        order: 2;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 30px;
        padding-bottom: 30px;
        position: absolute;
        top: 192px;
        width: 252px;
        left: calc(100vw - 252px);
        -webkit-box-shadow: 0 4px 15px -3px rgba(0, 0, 0, 0.4);
        box-shadow: 0 4px 15px -3px rgba(0, 0, 0, 0.4);
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
        color: black;
        text-decoration: none;
    }

    .horizontal-line {
        display: none;
    }
}
</style>