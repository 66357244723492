import * as Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies';
import Vuex from 'vuex';
import VueChartkick from 'vue-chartkick';

import 'sweetalert2/dist/sweetalert2.min.css';
import '../src/css/colors.css';
import '../src/css/components.css';
import '../src/css/layout.css';
import '../src/css/text.css';



/** Backend-Urls **/
//const backendUrl = "http://192.168.0.185:8080";
const backendUrl = "https://smallshops-server.ljmcoding.com:4003"
const btcpayUrl = "https://btc.ljmcoding.com"

const store = new Vuex.Store({
    state: {
        componentStates: {},
        shopStates: {},
        productStates: {}
    },
    mutations: {
        saveComponentState(state, { routeName, componentState }) {
            state.componentStates[routeName] = componentState;
        },
        saveShopState(state, { id, componentState }){
            state.shopStates[id] = componentState;
        },
        saveProductState(state, { id, componentState }){
            state.productStates[id] = componentState;
        }
    }
});

/** App-Setup **/
const app = Vue.createApp(App);
app.config.globalProperties.$backendURL = backendUrl; //global backend-server-url
app.config.globalProperties.$btcpayURL = btcpayUrl; //global backend-server-url
app.use(router) //vue-router
app.use(VueSweetalert2); //alert-library
app.use(VueCookies); //coockies
app.use(store);

import { Chart, BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js';

Chart.register(BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, BarElement);
app.use(VueChartkick.use(Chart)); //chart-library
app.mount('#app');


